import React from "react";

import styled from "styled-components";

import { Text, Box } from "../Core";

import { Container, Row, Col } from "react-bootstrap";

import { device } from "../../utils";

import quoteSVG from "./quote.svg";

const BoxStyled = styled(Box)`
  border-bottom: 1px solid #eae9f2;
  padding-top: 17px;
  padding-bottom: 45px;

  @media ${device.md} {
    padding-top: 90px;
    padding-bottom: 85px;
  }
`;

const TextStyled = styled(Text)`
  /* margin: 2rem; */
  &::after {
    position: absolute;
    top: -150px;
    left: -150px;
    z-index: -1;
    display: block;
    content: " ";
    /* background-color: green; */
    background-image: url(${quoteSVG});
    background-size: 300px 300px;
    height: 300px;
    width: 300px;
  }
`;

const Quote = ({ cms, data }) => {
  const aosText = !cms && {
    "data-aos": "zoom-in",
    "data-aos-duration": "1000",
    "data-aos-delay": "100",
    "data-aos-once": "true",
  };

  return (
    <>
      <BoxStyled>
        <Container>
          <Row className="align-items-center justify-content-center position-relative text-center py-5">
            <Col lg="12" xl="10">
              <TextStyled
                color="dark"
                className="font-italic"
                {...aosText}
                dangerouslySetInnerHTML={{ __html: data?.quote }}
              />
              {/* <AuthorWidget>
                <AuthorImage>
                  <img src={imgAuthor} alt="" className="img-fluid" />
                </AuthorImage>
                <AuthorText
                // data-aos="fade-right"
                // data-aos-duration="750"
                // data-aos-delay="500"
                // data-aos-once="true"
                // ALTERNATIVLEY
                >
                  <Title variant="card" mt={3}>
                    Jonathan Taylor
                  </Title>
                </AuthorText>
              </AuthorWidget> */}
            </Col>
          </Row>
        </Container>
      </BoxStyled>
    </>
  );
};

export default Quote;
