import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import Img from "gatsby-image";

const SectionStyled = styled(Section)`
  padding-bottom: 2rem;
`;

const ImageContainer = styled(Box)`
  padding: 20px;
  min-width: 100%;
  width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  @media ${device.xl} {
    width: 360px;
    min-width: 360px;
  }
  @media ${device.md} {
    min-height: 100%;
    margin-bottom: 1rem;
  }
`;

const Summary = ({ cms, data }) => {
  const aosImage = !cms && {
    "data-aos": "zoom-in",
    "data-aos-duration": "1000",
    "data-aos-delay": "100",
    "data-aos-once": "true",
  };

  const Image = ({ src, alt }) =>
    cms ? (
      <img src={src} alt={alt} {...aosImage} />
    ) : (
      <Img fluid={src} alt={alt} style={{ width: "100%", height: "100%" }} />
    );

  return (
    <>
      {/* <!-- Content section --> */}
      <SectionStyled bg="#f2f2f3">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg="12">
              <div className="text-center">
                <Title color="secondary">{data?.title}</Title>
                <Text dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {data.infoImages.map((image, index) => (
              <Col
                key={cms ? image : image.childImageSharp.fluid.originalName}
                lg="4"
                md="6"
                className="mb-4"
                {...aosImage}
              >
                <ImageContainer>
                  <Image
                    src={cms ? image : image.childImageSharp.fluid}
                    alt={
                      !cms
                        ? image.childImageSharp.fluid.originalName
                        : `Summary ${index}`
                    }
                  />
                </ImageContainer>
              </Col>
            ))}
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Summary;
