import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import Img from "gatsby-image";

import { Title, Section, Box, Text } from "../../components/Core";

const ImgContainer = styled.div``;

const Content2 = ({ cms, data }) => {
  const aosImage = !cms && {
    "data-aos": "fade-right",
    "data-aos-duration": "750",
    "data-aos-delay": "200",
    "data-aos-once": "true",
  };

  const aosText = !cms && {
    "data-aos": "fade-left",
    "data-aos-duration": "750",
    "data-aos-once": "true",
  };

  const Image = cms ? (
    <img
      src={data.image}
      alt={data?.title}
      className="img-fluid"
      {...aosImage}
    />
  ) : (
    <Img
      fluid={data.image.childImageSharp.fluid}
      alt={data?.title}
      style={{ width: "100%", height: "100%" }}
      className="img-fluid"
    />
  );

  return (
    <>
      {/* <!-- Content section 2 --> */}
      <Section>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="12" className="mb-4 mb-lg-0">
              <ImgContainer {...aosImage} className="p-lg-4">
                {Image}
              </ImgContainer>
            </Col>
            <Col lg="6" md="12">
              <Box className="pt-5 pt-lg-0" {...aosText}>
                <Title color="secondary">{data?.title}</Title>
                <Text mb={4} dangerouslySetInnerHTML={{ __html: data?.text }} />
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Content2;
