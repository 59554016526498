import React from "react";
import { graphql } from "gatsby";

import Hero from "../sections/landing/Hero";
import Content1 from "../sections/landing/Content1";
import Content2 from "../sections/landing/Content2";
import CTA from "../sections/landing/CTA";
import PageWrapper from "../components/PageWrapper";
import Quote from "../components/Quote";
import Reviews from "../sections/landing/Reviews";
// import Offer from "../sections/landing/Offer";
import Summary from "../sections/landing/Summary";
import Team from "../sections/landing/Team";

export const IndexPageTemplate = ({ data, cms = false, children }) => {
  return (
    <>
      <Hero cms={cms} data={data.heroSection} />
      <Quote cms={cms} data={data.quoteSection} />
      <Content1 cms={cms} data={data.contentSection_1} />
      <Content2 cms={cms} data={data.contentSection_2} />
      <Summary cms={cms} data={data.summarySection} />
      {/* <Offer cms={cms} /> */}
      {children}
      <Reviews cms={cms} data={data.reviewSection} />
      <CTA cms={cms} data={data.ctaSection} />
    </>
  );
};

const IndexPage = ({ data }) => {
  return (
    <>
      <PageWrapper headerDark>
        <IndexPageTemplate data={data.page.frontmatter}>
          <Team />
        </IndexPageTemplate>
      </PageWrapper>
    </>
  );
};
export default IndexPage;

export const query = graphql`
  query IndexPage {
    page: markdownRemark(fileAbsolutePath: { regex: "/pages/index.md/" }) {
      frontmatter {
        heroSection {
          title
          text
          backgroundImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          button {
            label
            link
            weblink
          }
        }
        quoteSection {
          quote
        }
        contentSection_1 {
          title
          text
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        contentSection_2 {
          title
          text
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        summarySection {
          title
          subtitle
          infoImages {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
        reviewSection {
          title
          subtitle
          reviews {
            text
            name
          }
        }
        ctaSection {
          title
          subtitle
          button {
            label
            link
          }
        }
      }
    }
  }
`;
