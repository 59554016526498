import React from "react";
import styled from "styled-components";
// import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import BackgroundImage from "gatsby-background-image";

// import imgHeroTab from "../../assets/image/png/l5-hero-image.png";
// import heroBackground from "../../../static/images/hero-background.png";

const SectionStyled = styled(Section)`
  background-image: ${(props) => {
    return `url("${props.backgroundImage}")`;
  }};
  background-size: cover;
  background-repeat: no-repeat;
  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) => `linear-gradient(
    90deg,
    #040e18 0%,
    ${theme.colors.secondary}00 80%)`};
  }
`;

const ImgRight = styled(Box)`
  position: relative;
  left: -100px;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: 0;
    left: auto;
    transform: translateX(25%);
  }
`;

const Hero = ({ cms, data }) => {
  // const aosImage = !cms && {
  //   "data-aos": "fade-left",
  //   "data-aos-duration": "750",
  //   "data-aos-delay": "1000",
  //   "data-aos-once": "true",
  // };

  const aosText = !cms && {
    "data-aos": "fade-right",
    "data-aos-duration": "750",
    "data-aos-delay": "500",
    "data-aos-once": "true",
  };

  // const dataAdjusted = cms ? {} : {};

  const HeroSection = (
    <SectionStyled
      className="position-relative"
      backgroundImage={cms ? data.backgroundImage : null}
    >
      <div className="pt-5" />
      <Container>
        <Row className="justify-content-center align-items-center position-relative">
          <Col md="5" className="order-md-2 position-static">
            <ImgRight>
              {/* <img
          src={imgHeroTab}
          alt=""
          className="img-fluid"
          {...aosImage}
        /> */}
            </ImgRight>
          </Col>
          <Col md="7" className="order-md-1">
            <div>
              <Box
                py={[null, null, null, 5]}
                pr={5}
                pt={[4, null]}
                {...aosText}
                style={{ zIndex: 20, position: "relative" }}
              >
                <Title variant="hero" color="light">
                  {data?.title}
                </Title>
                <Text
                  mb={4}
                  color="light"
                  opacity={0.7}
                  dangerouslySetInnerHTML={{ __html: data?.text }}
                />
                <div />
                <div className="d-flex flex-column align-items-start pt-3">
                  {data?.button && (
                    <>
                      <Button
                        href={data.button.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        mb={2}
                        variant="outline"
                      >
                        {data.button.label}
                      </Button>
                    </>
                  )}
                </div>
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  );

  if (cms) {
    return <div>{HeroSection}</div>;
  }

  return (
    <>
      {/* <!-- Hero Area --> */}
      <BackgroundImage
        Tag="section"
        fluid={data.backgroundImage.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        {HeroSection}
      </BackgroundImage>
    </>
  );
};

export default Hero;
