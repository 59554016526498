import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import ReviewCard from "../../components/ReviewCard";

const Reviews = ({ cms, data }) => {
  return (
    <>
      <Section bg="#f7f7fb" borderBottom="1px solid #EAE9F2;">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="9" className="text-center">
              <Box className="text-center">
                <Title color="secondary">{data?.title}</Title>
              </Box>
              <Text
                mb={4}
                dangerouslySetInnerHTML={{ __html: data?.subtitle }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            {data?.reviews?.map((node, index) => {
              return (
                <Col
                  lg="4"
                  md="6"
                  className="mb-4"
                  key={`review-${index}-${node.name}`}
                >
                  <ReviewCard name={node.name} excerpt={node.text} cms={cms}>
                    <Text
                      color="dark"
                      mt={4}
                      mb={3}
                      dangerouslySetInnerHTML={{ __html: node.text }}
                    />
                  </ReviewCard>
                </Col>
              );
            })}
          </Row>
          {/* <p
            className="mb-0 mt-5"
            css={`
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.5px;
              text-align: center;
            `}
          >
            <a href="/" target="_blank" className="text-decoration-none">
              <Span color="secondary">
                Check all 3,583 reviews on Amazon.com
              </Span>
            </a>
          </p> */}
        </Container>
      </Section>
    </>
  );
};

export default Reviews;
