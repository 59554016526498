import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Img from "gatsby-image";

import { Title, Section, Text } from "../../components/Core";

const ImgContainer = styled.div`
  margin-top: 50px;
`;

const Content1 = ({ cms, data }) => {
  const aosImage = !cms && {
    "data-aos": "fade-left",
    "data-aos-duration": "750",
    "data-aos-once": "true",
  };

  const aosText = !cms && {
    "data-aos": "fade-right",
    "data-aos-duration": "750",
    "data-aos-once": "true",
  };

  const Image = cms ? (
    <img
      src={data.image}
      alt={data?.title}
      className="img-fluid"
      {...aosImage}
    />
  ) : (
    <Img
      fluid={data.image.childImageSharp.fluid}
      alt={data?.title}
      style={{ width: "100%", height: "100%" }}
      className="img-fluid"
    />
  );

  return (
    <>
      {/* <!-- Content section 1 --> */}
      <Section bg="#F7F7FB" className="position-relative mb-4 mb-lg-0">
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className=" position-static order-lg-2">
              <ImgContainer className="pl-lg-3" {...aosImage}>
                {Image}
              </ImgContainer>
            </Col>
            <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
              <div {...aosText}>
                <div>
                  <Title color="secondary">{data?.title}</Title>
                  <Text
                    mb={4}
                    dangerouslySetInnerHTML={{ __html: data?.text }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Content1;
