import React from "react";

import Modal from "react-modal";
import styled from "styled-components";

import { Title, Box, Text } from "../../components/Core";

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    maxWidth: "960px",
    margin: "32px auto",
    padding: "2rem",
    border: 0,
    zIndex: 9999,
  },
};

const BoxStyled = styled(Box)`
  transition: box-shadow 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  &:hover {
    box-shadow: 0 5px 8px rgba(14, 86, 124, 0.17);
    cursor: pointer;
  }
`;

const ReviewCard = ({ className, name, excerpt, children, cms, ...rest }) => {
  // Modal stuff.
  const [modalOpen, setModalOpen] = React.useState(false);
  const modalCloseTimeout = 300;
  const closeModal = () => {
    setModalOpen(false);
    // setTimeout(() => navigate(`/`), modalCloseTimeout);
  };

  React.useEffect(() => {
    if (!cms) {
      Modal.setAppElement(`#___gatsby`);
    }
  }, [cms]);

  return (
    <>
      <BoxStyled
        bg="#ffffff"
        onClick={() => setModalOpen(true)}
        minHeight={["320px", null, `403px`]}
        border="1px solid"
        borderColor="border"
        p="35px"
        pb="30px"
        borderRadius={10}
        className={`d-flex flex-column ${className}`}
        {...rest}
        // data-aos="zoom-in"
        // data-aos-delay="500"
        // data-aos-duration="1000"
        // data-aos-once="true"
      >
        <Title variant="card" mb={1}>
          {name}
        </Title>
        <Text color="dark" mt={4} mb={3} className="font-italic">
          {excerpt.substring(0, 125)}...
        </Text>
        <Box className={`d-flex justify-content-between mt-auto`}>
          <div className="flex-grow-1">
            <Text
              fontSize="14px"
              fontWeight={400}
              lineHeight="1"
              textAlign="right"
              style={{ textDecoration: "underline" }}
            >
              Read More
            </Text>
          </div>
        </Box>
      </BoxStyled>

      {!cms && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          style={modalStyles}
          contentLabel="Modal"
          closeTimeoutMS={modalCloseTimeout}
        >
          <Title variant="card" className="pb-3">
            {name}
          </Title>
          <hr />
          {children}
          <Box className={`d-flex justify-content-between mt-auto`}>
            <div className="flex-grow-1">
              <Text
                fontSize="1.2rem"
                fontWeight={400}
                lineHeight="1"
                textAlign="right"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={closeModal}
              >
                Close
              </Text>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ReviewCard;
